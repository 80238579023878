import React, { useState, useCallback } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import { WarningType } from '../../../models/error-handling/WarningType';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ApplicationStore from '../../../services/ApplicationStore';
import { FormWarning } from '../../../models/error-handling/FormWarning';
import { OrganizationForm } from '../model';

export interface IOrganizationFormProps {
  store: ApplicationStore;
}

const successMessage = 'Organization created successfully.';
const errorMessage = 'A problem happened during the saving of the organization';

const CreateOrganization: React.FC<IOrganizationFormProps> = observer(
  ({ store }) => {
    const [currentFormError, setFormError] = useState<
      FormWarning | undefined
    >();
    const [createdId, setCreatedOrganizationId] = useState<
      number | undefined
    >();
    const [isLoading, setLoading] = useState(false);
    const [currentForm, setForm] = useState<Partial<OrganizationForm>>({
      name: '',
      description: '',
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        setFormError(undefined);
        setLoading(true);
        const createdOrg = await store.createOrganization({
          name: currentForm.name!!,
          description: currentForm.description!!,
        });
        setCreatedOrganizationId(createdOrg[0].organizationID);
        setFormError({
          status: WarningType.INFO,
          message: successMessage,
          type: 'info',
        });
      } catch (err: any) {
        setFormError({
          status: WarningType.ERROR,
          message: errorMessage,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    const handleOrgFieldChange = useCallback(
      (key: keyof OrganizationForm, value: string) => {
        setForm((prevForm) => ({
          ...prevForm,
          [key]: value,
        }));
      },
      [],
    );

    return (
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} width='100%'>
          <FormControl id='orgName' isRequired>
            <FormLabel>Name</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Input
                value={currentForm.name}
                onChange={(e) => handleOrgFieldChange('name', e.target.value)}
                placeholder='Enter organization name'
                aria-describedby='orgNameHelper'
              />
            </Skeleton>
          </FormControl>

          <FormControl id='orgDescription'>
            <FormLabel>Description</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Input
                value={currentForm.description}
                onChange={(e) =>
                  handleOrgFieldChange('description', e.target.value)
                }
                placeholder='Enter organization description'
                aria-describedby='orgDescriptionHelper'
              />
            </Skeleton>
          </FormControl>
          {currentFormError && (
            <Stack width='100%'>
              (
              <Alert
                status={currentFormError.status}
                data-testid='login-alert-message'
              >
                <AlertIcon />
                <AlertTitle>Info</AlertTitle>
                <AlertDescription>
                  <div>
                    {currentFormError.message}
                    {currentFormError.status !== WarningType.ERROR && (
                      <div>
                        Click{' '}
                        <Link to={`/organization/${createdId}`}>here</Link> to
                        the organization
                      </div>
                    )}
                  </div>
                </AlertDescription>
              </Alert>
              )
            </Stack>
          )}
          <Button
            mt={4}
            type='submit'
            colorScheme='green'
            isLoading={isLoading}
          >
            Save
          </Button>
        </VStack>
      </form>
    );
  },
);

export default CreateOrganization;
