import React, { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Center,
  useColorModeValue,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import SignUp from '../../components/SignUp/SignUp';
import { Link, useLocation } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { AUTH_ERROR_MAPPING } from '../../models/auth/AuthError';
import useAuth from '../../hooks/useAuth';
import { User } from '../../models/auth/User';
import { IError } from '../../models/error-handling/IError';
import { FormWarning } from '../../models/error-handling/FormWarning';

import { WarningType } from '../../models/error-handling/WarningType';
import { LoginOption } from '../../models/auth/LoginOption';

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = ({}) => {
  const bg = useColorModeValue('white', 'gray.800');
  const color = useColorModeValue('gray.800', 'gray.500');
  const navigate = useNavigate();
  const location = useLocation();
  const authService = useAuth();

  const [logionOption, setLoginTab] = useState(LoginOption.Login);
  const [isApiCallInProgress, setApiCallInProgress] = useState(false);
  const [apiError, setApiError] = useState<FormWarning | null>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setPassword(event.target.value);
  };

  function loginWithPopup(): void {
    setApiError(null);
    authService.loginWithPopup({
      onSuccess: () => {
        navigate('/dashboard');
      },
      onError: (error) => {
        const userFriendlyMessage =
          AUTH_ERROR_MAPPING[error.code] || 'An unexpected error occurred.';
        setApiError({
          message: userFriendlyMessage,
          status: WarningType.ERROR,
        });
      },
    });
  }

  function loginWithPassword():
    | MouseEventHandler<HTMLButtonElement>
    | undefined {
    setApiCallInProgress(true);

    authService.signInWithEmailAndPassword({
      email,
      password,
      onSuccess: (user: User) => {
        navigate('/dashboard');
        setApiCallInProgress(false);
        window.dispatchEvent(new CustomEvent('organizations', {}));
      },
      onError: (error: IError) => {
        const userFriendlyMessage =
          AUTH_ERROR_MAPPING[error.code] || 'An unexpected error occurred.';
        setApiError({
          message: userFriendlyMessage,
          status: WarningType.ERROR,
        });
        setApiCallInProgress(false);
      },
    });
    return undefined;
  }

  async function handlePasswordReset() {
    if (!email) {
      setApiError({
        message: 'Please enter your email address.',
        status: WarningType.ERROR,
      });
      return;
    }

    authService.sendPasswordResetEmail({
      email,
      onSuccess: () => {
        setApiError({
          message: 'A password reset link has been sent to your email.',
          status: WarningType.INFO,
          type: 'password-reset',
        });
      },
      onError: (error) => {
        const userFriendlyMessage =
          AUTH_ERROR_MAPPING[error.code] || 'An unexpected error occurred.';
        setApiError({
          message: userFriendlyMessage,
          status: WarningType.ERROR,
        });
      },
    });
  }

  React.useEffect(() => {
    if (location.pathname === '/login/signup') {
      setLoginTab(LoginOption.SignUp);
    } else {
      setLoginTab(LoginOption.Login);
    }
  }, [location]);

  return (
    <Box
      maxWidth='500px'
      bg={bg}
      color={color}
      borderRadius='2xl'
      mx='auto'
      my='10%'
    >
      <Center>
        <Flex
          flexDirection='column'
          width='100%'
          bg={useColorModeValue('white', 'gray.700')}
        >
          <Tabs bgColor={bg} index={logionOption === LoginOption.Login ? 0 : 1}>
            <TabList>
              <Link to='/login/signin'>
                <Tab>Sign in</Tab>
              </Link>
              <Link to='/login/signup'>
                <Tab>Sign up</Tab>
              </Link>
            </TabList>
            <TabPanels>
              <TabPanel>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type='email'
                    data-testid='email'
                    variant='filled'
                    value={email}
                    onChange={handleEmailChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type='password'
                    data-testid='password'
                    variant='filled'
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </FormControl>
                {apiError && (
                  <Stack spacing={6} mt={6}>
                    (
                    <Alert
                      status={apiError.status}
                      data-testid='login-alert-message'
                    >
                      <AlertIcon />
                      <AlertTitle mr={2}>Info</AlertTitle>
                      <AlertDescription>{apiError.message}</AlertDescription>
                    </Alert>
                    )
                  </Stack>
                )}
                <Stack spacing={6} mt={6}>
                  <Button
                    colorScheme='blue'
                    data-testid='sign-in-with-password-btn'
                    type='submit'
                    onClick={() => loginWithPassword()}
                    disabled={isApiCallInProgress}
                  >
                    {isApiCallInProgress ? (
                      <Spinner size='sm' />
                    ) : (
                      'Sign in with password'
                    )}
                  </Button>
                </Stack>
                <Stack spacing={4} marginTop='1rem'>
                  <Center>
                    <Button
                      w={'full'}
                      variant={'outline'}
                      onClick={() => loginWithPopup()}
                      leftIcon={<FcGoogle />}
                    >
                      <Center>
                        <Text>Sign in with Google</Text>
                      </Center>
                    </Button>
                  </Center>
                </Stack>
                <Stack spacing={6} mt={6}>
                  <Text
                    mt={2}
                    color={'blue.500'}
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handlePasswordReset}
                  >
                    Forgot password?
                  </Text>
                </Stack>
              </TabPanel>
              <TabPanel>
                <SignUp authService={authService} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Center>
    </Box>
  );
};

export default LoginPage;
