import {
  EmailAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword
} from '@firebase/auth';
import Firebase, { FirebaseApi } from './Firebase';
import { User } from '../models/auth/User';
import { AuthStateService } from './AuthStateService';
import {
  CreateUserWithEmailAndPasswordCallback,
  IAuthService,
  LoginWithPopupCallback,
  PasswordResultCallback,
  SignInWithEmailPasswordCallback,
  UpdatePasswordCallback,
} from './IAuthenticationService';
import AppClientService from './AppClientService';

export class FirebaseAuthService
  extends AuthStateService
  implements IAuthService
{
  constructor(
    private readonly firebase: Firebase,
    private readonly appClientService: AppClientService,
  ) {
    super();
  }

  getFirebaseContext(): FirebaseApi {
    return this.firebase.getFirebaseContext();
  }

  async signOut(): Promise<void> {
    const auth = getAuth();
    try {
      await signOut(auth);
      this.setAuthenticated(false);
    } catch (error) {
      console.log(error);
    }
  }

  async sendPasswordResetEmail({
    email,
    onSuccess,
    onError,
  }: PasswordResultCallback) {
    const auth = this.getFirebaseContext().auth;
    try {
      await sendPasswordResetEmail(auth, email);
      onSuccess();
    } catch (error: any) {
      onError({
        code: error.code,
      });
    }
  }

  async updatePassword({
    currentPassword,
    newPassword,
    onError,
    onSuccess,
  }: UpdatePasswordCallback) {
    const auth = this.getFirebaseContext().auth;
    const currentUser = auth.currentUser!;
    const credential = EmailAuthProvider.credential(
      currentUser.email!,
      currentPassword,
    );
    try {
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
      onSuccess();
    } catch (error: any) {
      onError({
        code: error.code,
      });
    }
  }

  createUserWithEmailAndPassword({
    username,
    email,
    password,
    onSuccess,
    onError,
  }: CreateUserWithEmailAndPasswordCallback) {
    const auth = this.getFirebaseContext().auth;
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user.email) {
          throw new Error('Cannot authenticate user');
        } else {
          user.getIdToken(true).then((idToken) => {
            const internalUser: Partial<User> = {
              email,
              displayName: username,
              idToken,
            };

            this.appClientService
              .createInternalUser(internalUser)
              .then(({ user }) => {
                const signInUser = {
                  id: user.userID,
                  email: user.email,
                  displayName: user.username,
                  idToken,
                  role: user.role,
                };
                this.signIn(signInUser);
                onSuccess(signInUser);
              });
          });
        }
      })
      .catch((error) => {
        onError({
          code: error.code,
        });
      });
  }

  signInWithEmailAndPassword({
    email,
    password,
    onError,
    onSuccess,
  }: SignInWithEmailPasswordCallback): void {
    const auth = this.getFirebaseContext().auth;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user.email) {
          throw new Error('Cannot authenticate user');
        }
        userCredential.user.getIdToken(true).then((idToken) => {
          const internalUser: Partial<User> = {
            email,
            displayName: user.displayName || user.email || '',
            idToken,
            id: user.uid,
          };
          this.appClientService
            .createInternalUser(internalUser)
            .then(({ user }) => {
              const signInUser = {
                id: user.userID,
                email: user.email,
                displayName: user.username,
                idToken,
                role: user.role,
              };
              this.signIn(signInUser);
              onSuccess(signInUser);
            });
        });
      })
      .catch((error) => {
        onError({
          code: error.code,
        });
      });
  }

  loginWithPopup({ onSuccess, onError }: LoginWithPopupCallback): void {
    const auth = this.getFirebaseContext().auth;
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user.email) {
          throw new Error('Cannot authenticate user');
        }
        user.getIdToken(true).then((idToken) => {
          const internalUser: Partial<User> = {
            email: user.email || '',
            displayName: user.displayName || user.email || '',
            idToken,
            id: user.uid,
          };
          this.appClientService
            .createInternalUser(internalUser)
            .then(({ user }) => {
              const signInUser = {
                id: user.userID,
                email: user.email,
                displayName: user.username,
                idToken,
                role: user.role,
              };
              this.signIn(signInUser);
              onSuccess(signInUser);
            });
        });
      })
      .catch((error) => {
        onError({
          code: error.code,
        });
      });
  }
}
