// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { Auth, getAuth } from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJKJqFcd_IrPyirpQyZ2gXLVArapt8hvU",
  authDomain: "gf-app-store.firebaseapp.com",
  projectId: "gf-app-store",
  storageBucket: "gf-app-store.appspot.com",
  messagingSenderId: "790779964352",
  appId: "1:790779964352:web:4566c37206591bfbf7b625",
  measurementId: "G-8NJY10H092",
};



export interface FirebaseApi {
  app: any;
  analytics: any;
  auth: Auth;
}

export default class Firebase {
  private firebaseContext: FirebaseApi | null = null;

  initializeFirebase(): void {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    this.firebaseContext = {
      app,
      analytics,
      auth,
    };
  }

  getFirebaseContext(): FirebaseApi {
    if (!this.firebaseContext) {
      throw new Error("Could not get firebase");
    }
    return this.firebaseContext;
  }
}
