import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Collapse,
  Text,
  Badge,
  Spinner,
  Grid,
  GridItem,
  Link,
  Button,
  VStack,
} from "@chakra-ui/react";
import PlatformIcon from "../PlatformIcon/PlatformIcon";
import BuildsMock from "./Mock";
import { CheckIcon, DownloadIcon, SettingsIcon } from "@chakra-ui/icons";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import Pagination from "../Pagination/Pagination";

export interface TestResults {
  passed: number;
  failed: number;
  skipped: number;
}

export interface TableRowData {
  platform: string;
  appVersion: string;
  workspace: string;
  architecture: string;
  description: string;
  target: string;
  commit: string;
  branch: string;
  author: string;
  time: string;
  platformVersion: string;
  buildId: string;
  buildType: string;
  tags?: string[];
  fileSize?: string;
  downloadCount?: number;
  testCoverage?: string;
  testResults?: TestResults;
  issues?: Array<{
    id: number;
    title: string;
    status: string;
  }>;
}

interface TestResultsProps {
  testResults: TestResults;
}

const TestResultsComponent: React.FC<TestResultsProps> = ({ testResults }) => {
  const { passed, failed, skipped } = testResults;

  return (
    <GridItem colSpan={1}>
      <VStack align="start" spacing={2}>
        <Text fontWeight="bold" mt={1} mb={1}>
          Test Results
        </Text>
        <Badge colorScheme="green">{`Passed: ${passed}`}</Badge>
        <Badge colorScheme="red">{`Failed: ${failed}`}</Badge>
        <Badge colorScheme="yellow">{`Skipped: ${skipped}`}</Badge>
      </VStack>
    </GridItem>
  );
};

const ExpandableTable: React.FC = () => {
  const [rowData, setRowData] = useState<TableRowData[]>([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  function fetchData(): void {
    const fetchData = new Promise((resolve) => {
      resolve(BuildsMock);
    });

    fetchData.then((response: any) => {
      setIsLoading(true);
      setRowData([]);
      setTimeout(() => {
        setRowData(response);
        setIsLoading(false);
      }, 500);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <>
      <Box bgColor="white" m="1rem" borderRadius="2xl">
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Table size="lg">
            <Thead>
              <Tr>
                <Th>Platform</Th>
                <Th>App Version</Th>
                <Th>Workspace</Th>
                <Th>Build Type</Th>
                <Th>Branch</Th>
                <Th>Author</Th>
                <Th>Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rowData.map((row, index) => (
                <React.Fragment key={index}>
                  <Tr
                    onClick={() => handleRowClick(index)}
                    _hover={{ cursor: "pointer" }}
                  >
                    <Td>
                      <PlatformIcon name={row.platform} />
                    </Td>
                    <Td>
                      <Badge colorScheme="blue" fontSize="md">
                        {row.appVersion}
                      </Badge>
                    </Td>
                    <Td>{row.workspace}</Td>
                    <Td>{row.buildType}</Td>
                    <Td>
                      <Link>{row.branch}</Link>
                    </Td>
                    <Td>{row.author}</Td>
                    <Td>{row.time}</Td>
                  </Tr>
                  {expandedRowIndex === index && (
                    <Tr>
                      <Td colSpan={12}>
                        <Collapse in={expandedRowIndex === index}>
                          <Grid
                            templateColumns="repeat(3, 1fr)"
                            padding={0}
                            boxShadow="md"
                            borderRadius="md"
                            backgroundColor="white"
                          >
                            <GridItem colSpan={1}>
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Build ID:{" "}
                              </Text>
                              <Text>{row.buildId}</Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Commit{" "}
                              </Text>
                              <Text>{row.commit}</Text>
                            </GridItem>
                            <GridItem colSpan={1} maxWidth="300px">
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Build Description{" "}
                              </Text>
                              <Text>{row.description}</Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Target{" "}
                              </Text>
                              <Text>{row.target}</Text>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Architecture
                              </Text>
                              <Badge ml="0.25rem">{row.architecture}</Badge>
                            </GridItem>
                            <GridItem colSpan={1} maxWidth="300px">
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Tags
                              </Text>
                              {row.tags?.map((tag) => (
                                <Badge ml="0.25rem" key={tag}>{tag}</Badge>
                              ))}
                            </GridItem>
                            <GridItem colSpan={1}>
                              <Text fontWeight="bold" mt={1} mb={1}>
                                Symbol
                              </Text>
                              <Text mb={2}>MapleStory.dbg</Text>
                              <Text mb={2}>125 MB</Text>
                              <Button>
                                <Box as={DownloadIcon} mr="0.25rem" />
                                Download
                              </Button>
                              <Button
                                ml="0.5rem"
                                onClick={() => {}}
                                isDisabled={true}
                              >
                                Share
                              </Button>
                            </GridItem>
                            <GridItem colSpan={1} mt={1} mb={1}>
                              <Text fontWeight="bold">Binary</Text>
                              <Text mb={2}>MapleStory.exe</Text>
                              <Text mb={2}>325 MB</Text>
                              <Button p="1rem">
                                <Box as={DownloadIcon} mr="0.25rem" />
                                Download
                              </Button>
                              <QRCodeModal />
                              <Button
                                ml="0.5rem"
                                p="1rem"
                                onClick={() => {}}
                                isDisabled={true}
                              >
                                Share
                              </Button>
                            </GridItem>
                            {row.testResults && (
                              <GridItem colSpan={1}>
                                <TestResultsComponent
                                  testResults={row.testResults}
                                />
                              </GridItem>
                            )}
                            <GridItem colSpan={1} mt="0.5rem">
                              <Text fontWeight="bold">Release</Text>
                              <Button colorScheme="blue" size="sm" mr="0.5rem">
                                <Box as={SettingsIcon} mr="0.25rem" />
                                Edit
                              </Button>
                              <Button
                                colorScheme="yellow"
                                size="sm"
                                mr="0.5rem"
                              >
                                <Box as={CheckIcon} mr="0.25rem" />
                                Delete
                              </Button>
                            </GridItem>
                          </Grid>
                        </Collapse>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        )}

        <Pagination onNext={() => fetchData()}/>
      </Box>
    </>
  );
};

export default ExpandableTable;
