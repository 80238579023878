import React, { useMemo } from 'react';
import {
  Box,
  Flex,
  Heading,
  VStack,
  StackDivider,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

const NavLinkSection = ({ title, links }: Section) => (
  <VStack
    spacing={2}
    alignItems='flex-start'
    divider={<StackDivider borderColor='gray.300' />}
  >
    <Heading size='md'>{title}</Heading>
    {links.map((link, i) => (
      <Link key={i} href={link.href} aria-label={link.label}>
        {link.label}
      </Link>
    ))}
  </VStack>
);

type Link = {
  href: string;
  label: string;
};

type Section = {
  title: string;
  links: Link[];
};

export const sections: Section[] = [
  { title: 'Company', links: [{ href: '/about', label: 'About Us' }] },
  {
    title: 'Resources',
    links: [
      { href: '#', label: 'Documentation' },
      { href: '#', label: 'FAQ' },
      { href: '#', label: 'Contact Support' },
    ],
  },
  {
    title: 'Legal',
    links: [
      { href: '#', label: 'Terms of Service' },
      { href: '#', label: 'Privacy Policy' },
      { href: '#', label: 'Cookie Policy' },
    ],
  },
];

const Footer: React.FC = () => {
  const color = useColorModeValue('white', 'gray.200');
  const bg = useColorModeValue('gray.700', 'gray.500');

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box bg={bg} color={color} marginTop='auto' as='footer'>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent='space-around'
        alignItems='center'
        py={6}
        px={{ base: 4, md: 16 }}
        maxW='auto'
      >
        {sections.map((section, i) => (
          <NavLinkSection key={i} {...section} />
        ))}
      </Flex>
      <Text textAlign='center' py={4}>
        &copy; {currentYear} Game Forge Labs. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
