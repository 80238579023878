import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
  VStack,
  Button,
  Heading,
  Flex,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Skeleton,
} from '@chakra-ui/react';
import { SearchIcon, StarIcon } from '@chakra-ui/icons';
import { ApplicationStoreContext } from '../../hooks/useApplicationStore';
import { Link, useNavigate } from 'react-router-dom';
import CustomAvatar from '../../components/Avatar/Avatar';
import { WarningType } from '../../models/error-handling/WarningType';
import { FormWarning } from '../../models/error-handling/FormWarning';
import { FaSpinner } from 'react-icons/fa';
import { OrganizationDTO } from '../../types/app-store';

const FavoriteStar = () => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <Box onClick={toggleFavorite} cursor='pointer'>
      <StarIcon color={isFavorite ? 'yellow.400' : 'gray.400'} />
    </Box>
  );
};

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { applicationStore } = useContext(ApplicationStoreContext);
  const [search, setSearch] = useState('');
  const [orgsApiError, setOrgsApiError] = useState<FormWarning | null>(null);
  const [organizations, setOrganizations] = useState<OrganizationDTO[]>([]);
  const [isLoadingOrganization, setLoadingOrganization] = useState(false);

  const filteredOrganizations = organizations.filter((organization: any) =>
    organization.name.toLowerCase().includes(search.toLowerCase()),
  );

  async function refreshOrganization() {
    setLoadingOrganization(true);
    try {
      const orgs = await applicationStore.getOrganizations();
      setOrganizations(orgs);
    } catch (err: any) {
      setOrgsApiError({
        message: err.detail || 'Could not load your organizations.',
        status: WarningType.ERROR,
      });
    } finally {
      setLoadingOrganization(false);
    }
  }

  useEffect(() => {
    refreshOrganization();
  }, []);

  return (
    <VStack spacing={5} p={5}>
      <InputGroup w='100%'>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon color='gray.400' />
        </InputLeftElement>
        <Input
          type='text'
          placeholder='Search organizations'
          onChange={(e) => setSearch(e.target.value)}
          focusBorderColor='blue.500'
        />
      </InputGroup>
      <Button
        colorScheme='blue'
        size='lg'
        onClick={() => navigate('/organization/create')}
      >
        Create or Join an Organization
      </Button>{' '}
      {isLoadingOrganization && <FaSpinner></FaSpinner>}
      {orgsApiError && (
        <Alert status={orgsApiError.status} data-testid='login-alert-message'>
          <AlertIcon />
          <AlertTitle>Info</AlertTitle>
          <AlertDescription>
            <div>
              {orgsApiError.message}
              {orgsApiError.status != WarningType.ERROR && (
                <div>
                  Click <Link to='/dashboard'>here</Link> to the organization
                </div>
              )}
            </div>
          </AlertDescription>
        </Alert>
      )}
      {!isLoadingOrganization && !orgsApiError && (
        <SimpleGrid
          columns={[1, 2, 3, 4, 5]}
          spacing={5}
          w='100%'
          maxW='1200px'
        >
          {filteredOrganizations.map((org: any) => (
            <Box
              borderWidth='1px'
              borderRadius='lg'
              bg='white'
              key={org.organizationID}
              overflow='hidden'
              cursor='pointer'
              _hover={{ boxShadow: 'md' }}
            >
              <Link
                key={org.organizationID}
                to={`/organization/${org.organizationID}`}
              >
                {' '}
                <CustomAvatar name={org.name}></CustomAvatar>
              </Link>
              <Box p={4}>
                <Link
                  key={org.organizationID}
                  to={`/organization/${org.organizationID}`}
                >
                  <Heading size='md' mb={2}>
                    {org.name}
                  </Heading>
                  <Text fontSize='sm' mb={3}>
                    {org.description}
                  </Text>
                </Link>
              </Box>
              <Flex p={2}>
                <FavoriteStar />
                <Button
                  size='sm'
                  colorScheme='green'
                  marginLeft='4'
                  p={1}
                  width='100%'
                >
                  Access workspaces
                </Button>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </VStack>
  );
};

export default Dashboard;
