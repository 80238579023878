export const AUTH_ERROR_MAPPING: Record<string, string> = {
    "auth/email-already-in-use":
      "The email address is already in use by another account.",
    "auth/invalid-email":
      "The email address is not valid. Please enter a valid email address.",
    "auth/user-disabled":
      "The user corresponding to this email has been disabled. Please contact support for assistance.",
    "auth/user-not-found":
      "There is no user associated with the given email. Please check your email and try again.",
    "auth/wrong-password":
      "The password you entered is incorrect. Please try again or reset your password if you've forgotten it.",
    "auth/too-many-requests": "Too many requests. Please try again later.",
    "auth/weak-password":
      "The password is too weak. Please choose a stronger password.",
    "auth/operation-not-allowed":
      "Password sign-in is disabled for this project. Please contact support.",
    "auth/reset-password-exceeded":
      "You have exceeded the allowed number of password reset requests. Please try again later.",
    "auth/expired-action-code":
      "The password reset link has expired. Please request a new one.",
    "auth/invalid-action-code":
      "The password reset link is invalid. Please request a new one.",
    "auth/popup-closed-by-user":
      "The authentication popup was closed by the user. Please try again and make sure to keep the popup window open until the authentication process is complete.",
  };
  