import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import { WarningType } from '../../../models/error-handling/WarningType';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import ApplicationStore from '../../../services/ApplicationStore';
import { OrganizationDTO } from '../../../types/app-store';
import { FormWarning } from '../../../models/error-handling/FormWarning';
import { OrganizationForm } from '../model';

export interface IOrganizationFormProps {
  store: ApplicationStore;
}

const EditOrganization = ({ store }: IOrganizationFormProps) => {
  const [currentFormError, setFormError] = useState<FormWarning | undefined>();
  const { organizationId: organizationID } = useParams();
  const [currentOrganization, setCurrentOrganization] = useState<
    OrganizationDTO | undefined
  >();
  const [isLoading, setLoading] = useState(false);
  const [currentForm, setForm] = useState<Partial<OrganizationForm>>({
    name: currentOrganization?.name,
    description: currentOrganization?.description,
  });

  useEffect(() => {
    async function fetchOrganization() {
      setLoading(true);
      try {
        const response = await store.getOrganizations();
        const orgList = response.filter(
          (org: OrganizationDTO) =>
            org.organizationID === Number(organizationID),
        );
        if (orgList.length > 0) {
          const [currentOrg] = orgList;
          setForm({
            organizationID: String(currentOrg.organizationID),
            name: currentOrg.name,
            description: currentOrg.description,
          });
        }
      } catch (Err: any) {
        setFormError({
          status: WarningType.ERROR,
          message: 'A problem happened during the update of the organization',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    if (organizationID) {
      fetchOrganization();
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setFormError(undefined);
      setLoading(true);
      await store.createOrganization({
        organizationID: organizationID,
        name: currentForm.name!!,
        description: currentForm.description!!,
      });
      setFormError({
        status: WarningType.INFO,
        message: 'Organization updated succesfully.',
        type: 'info',
      });
    } catch (err: any) {
      setFormError({
        status: WarningType.ERROR,
        message: 'A problem happened during the update of the organization',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOrgFieldChange = (key: string, value: string) => {
    setForm({
      ...currentForm,
      [key]: value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} width='100%'>
          <FormControl id='orgName' isRequired>
            <FormLabel>Name</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Input
                value={currentForm.name}
                onChange={(e) => handleOrgFieldChange('name', e.target.value)}
                placeholder='Enter organization name'
                aria-describedby='orgNameHelper'
              />
            </Skeleton>
          </FormControl>

          <FormControl id='orgDescription'>
            <FormLabel>Description</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Input
                value={currentForm.description}
                onChange={(e) =>
                  handleOrgFieldChange('description', e.target.value)
                }
                placeholder='Enter organization description'
                aria-describedby='orgDescriptionHelper'
              />
            </Skeleton>
          </FormControl>
          {currentFormError && (
            <Stack width='100%'>
              (
              <Alert
                status={currentFormError.status}
                data-testid='login-alert-message'
              >
                <AlertIcon />
                <AlertTitle>Info</AlertTitle>
                <AlertDescription>{currentFormError.message}</AlertDescription>
              </Alert>
              )
            </Stack>
          )}
          <Button
            mt={4}
            type='submit'
            colorScheme='green'
            isLoading={isLoading}
          >
            Save
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default observer(({ store }: IOrganizationFormProps) => {
  return <EditOrganization store={store} />;
});
