import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Select,
  VStack,
  Heading,
  Avatar,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
} from '@chakra-ui/react';
import { FaImage } from 'react-icons/fa';
import { DeleteIcon } from '@chakra-ui/icons';
import { AiOutlineCheck } from 'react-icons/ai';

type Role = 'ADMIN' | 'READ' | 'WRITE' | 'RELEASE';

interface User {
  email: string;
  role: Role;
}

interface Workspace {
  name: string;
  image?: string;
  githubRepository: string;
  users: User[];
}

interface Props {
  onSubmit?: (workspace: Workspace) => void;
}

export default function WorkspaceForm({ onSubmit }: Props) {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [githubRepo, setGithubRepo] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState<Role>('READ');
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState('');
  const [s3BucketConfig, setS3BucketConfig] = useState<any>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log(image);
    e.preventDefault();
  };

  const handleAddUser = () => {
    if (userEmail.trim() === '') {
      setError('Please enter a valid email address');
      return;
    }
    if (users.find((user) => user.email === userEmail)) {
      setError('User with this email address already added');
      return;
    }
    setUsers([...users, { email: userEmail, role: userRole }]);
    setUserEmail('');
    setUserRole('READ');
  };

  return (
    <Box bgColor="white" p="1rem">
      <Flex alignItems="center" px="1rem" py="1rem">
        <Avatar
          size="xl"
          name="Workspace Image"
          src={`https://ui-avatars.com/api/?name=WK&background=random`}
        />
        <Box ml={4}>
          <Heading size="lg">Workspace Configuration</Heading>
        </Box>
      </Flex>

      <Tabs variant="enclosed" maxW={'40rem'}>
        <TabList>
          <Tab>Configure</Tab>
          <Tab>Manage access</Tab>
          <Tab>Storage Configuration</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <form onSubmit={handleSubmit}>
                <VStack spacing={4} align="stretch">
                  <FormControl /*isRequired isInvalid={name.trim() === ""}*/>
                    <FormLabel htmlFor="name">Workspace Name</FormLabel>
                    <Input
                      id="name"
                      placeholder="MapleStory QA"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <FormErrorMessage>
                      Please enter a workspace name
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="image">Image</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FaImage />}
                      />
                      <Input
                        id="image"
                        placeholder="MapleStory.png"
                        onChange={(e) => setImage(e.target.value)}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl /*isRequired isInvalid={githubRepo.trim() === ""}*/
                  >
                    <FormLabel htmlFor="githubRepo">
                      GitHub Repository
                    </FormLabel>
                    <Input
                      id="githubRepo"
                      placeholder="https://github.com/MapleStoryA/orion-server"
                      value={githubRepo}
                      onChange={(e) => setGithubRepo(e.target.value)}
                    />
                    <FormErrorMessage>
                      Please enter a valid GitHub repository URL
                    </FormErrorMessage>
                  </FormControl>

                  <Button type="submit" colorScheme="green" mt={4}>
                    Save Workspace
                  </Button>
                </VStack>
              </form>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box mt={4}>
              <FormControl pb="1rem">
                <FormLabel htmlFor="email">Invite Users</FormLabel>
                <InputGroup>
                  <Input
                    id="email"
                    placeholder="Enter user's email address"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  <Select
                    id="role"
                    value={userRole}
                    onChange={(e) => setUserRole(e.target.value as Role)}
                    ml={2}>
                    <option value="READ">Read Only</option>
                    <option value="WRITE">Read and Write</option>
                    <option value="WRITE">Release</option>
                    <option value="ADMIN">Admin</option>
                  </Select>
                  <Button
                    colorScheme="green"
                    onClick={handleAddUser}
                    ml={2}
                    p={5}
                    size="sm">
                    Add
                  </Button>
                </InputGroup>
                {error && <FormErrorMessage mt={2}>{error}</FormErrorMessage>}
              </FormControl>
              <FormLabel>Member:</FormLabel>
              <VStack spacing={2} align="stretch">
                <TableContainer>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>User / Group</Th>
                        <Th>Type</Th>
                        <Th>Permission</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>gameforgelabs.com</Td>
                        <Td>Group</Td>
                        <Td>READ</Td>
                        <Td>
                          <Button
                            colorScheme="green"
                            size="sm"
                            leftIcon={<DeleteIcon />}>
                            Edit
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>admin@gameforgelabs.com</Td>
                        <Td>E-mail</Td>
                        <Td>READ</Td>
                        <Td>
                          <Button
                            colorScheme="green"
                            size="sm"
                            leftIcon={<DeleteIcon />}>
                            Edit
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>release@gameforgelabs.com</Td>
                        <Td>E-mail</Td>
                        <Td>RELEASE</Td>
                        <Td>
                          <Button
                            colorScheme="green"
                            size="sm"
                            leftIcon={<DeleteIcon />}>
                            Edit
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            </Box>
          </TabPanel>
          <TabPanel>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel htmlFor="bucketName">Bucket Name</FormLabel>
                <Input
                  id="bucketName"
                  placeholder="Enter your S3 bucket name"
                  value={s3BucketConfig.bucketName || ''}
                  onChange={(e) =>
                    setS3BucketConfig({
                      ...s3BucketConfig,
                      bucketName: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="accessKey">Access Key</FormLabel>
                <Input
                  id="accessKey"
                  placeholder="Enter your S3 bucket access key"
                  value={s3BucketConfig.accessKey || ''}
                  onChange={(e) =>
                    setS3BucketConfig({
                      ...s3BucketConfig,
                      accessKey: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="secretKey">Secret Key</FormLabel>
                <Input
                  id="secretKey"
                  placeholder="Enter your S3 bucket secret key"
                  value={s3BucketConfig.secretKey || ''}
                  onChange={(e) =>
                    setS3BucketConfig({
                      ...s3BucketConfig,
                      secretKey: e.target.value,
                    })
                  }
                />
              </FormControl>
              <Button
                colorScheme="green"
                leftIcon={<AiOutlineCheck />}
                onClick={() => console.log('Test Connection', s3BucketConfig)}>
                Save
              </Button>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
