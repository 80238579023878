import axios, { AxiosError } from 'axios';
import {
  AxiosRestApplicationClient,
  OrganizationDTO,
  OrganizationMemberDTO,
  UserDTO,
  UserResponseDTO,
} from '../types/app-store';
import { User as InternalUser } from '../models/auth/User';



export default class AppClientService {
  private baseUrl = 'http://localhost:8080';

  private createAxiosClient() {
    const axiosInstance = axios.create({
      baseURL: this.baseUrl,
    });

    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${this.getIdToken()}`;
    return axiosInstance;
  }

  getIdToken(): string {
    const authJson = localStorage.getItem('auth');
    if (authJson) {
      const state = JSON.parse(authJson);
      return state.user.idToken;
    }
    throw new Error('Could not find token');
  }

  async getOrganizations() {
    const axiosInstance = this.createAxiosClient();
    const client = new AxiosRestApplicationClient(this.baseUrl, axiosInstance);
    return client
      .getOrganizations()
      .then((res) => res.data)
      .catch((res) => {
        throw res.response.data;
      });
  }

  async getOrganizationMembers(organizationID: number) {
    const axiosInstance = this.createAxiosClient();
    const client = new AxiosRestApplicationClient(this.baseUrl, axiosInstance);
    return client
      .getOrganizationMembers(organizationID)
      .then((res) => res.data)
      .catch((res) => {
        throw res.response.data;
      });
  }

  async createInternalUser(
    user: Partial<InternalUser>,
  ): Promise<UserResponseDTO> {
    try {
      const axiosInstance = axios.create({
        baseURL: this.baseUrl,
        withCredentials: false,
      });

      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.idToken}`;

      const client = new AxiosRestApplicationClient(
        this.baseUrl,
        axiosInstance,
      );

      const ret = await client.createUser({
        userID: user.id,
        username: user.email,
        email: user.email!,
      } as UserDTO);
      return ret.data;
    } catch (error) {
      throw error;
    }
  }

  async createOrganization(
    org: Partial<OrganizationDTO>,
  ): Promise<OrganizationDTO[]> {
    const axiosInstance = this.createAxiosClient();
    const client = new AxiosRestApplicationClient(this.baseUrl, axiosInstance);
    return client
      .createOrganization(org as OrganizationDTO)
      .then((res) => res.data)
      .catch((res) => {
        throw res.response.data;
      });
  }

  
  
  async addOrganizationMember(
    organizationId: number,
    dto: Partial<OrganizationMemberDTO>,
  ): Promise<OrganizationMemberDTO[]> {
    const axiosInstance = this.createAxiosClient();
    const client = new AxiosRestApplicationClient(this.baseUrl, axiosInstance);
    return client
      .addOrganizationMember(organizationId, dto as OrganizationMemberDTO)
      .then((res) => res.data)
      .catch((res) => {
        throw res.response.data;
      });
  }


  async expelOrganizationMember(
    organizationId: number,
    memberId: number
  ): Promise<OrganizationMemberDTO[]> {
    const axiosInstance = this.createAxiosClient();
    const client = new AxiosRestApplicationClient(this.baseUrl, axiosInstance);
    return client
      .expelOrganizationMember(organizationId, memberId)
      .then((res) => res.data)
      .catch((res) => {
        throw res.response.data;
      });
  }
}
