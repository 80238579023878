import React from "react";
import {
  Button,
  Center,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <Center h="100vh" bg="gray.100">
      <VStack spacing={6}>
        <Heading as="h1" size="2xl">
          Oops! 🌌
        </Heading>
        <Text fontSize="xl">
          The page you're looking for doesn't exist in this universe.
        </Text>
        
        
        <Button colorScheme="teal" onClick={navigateToHome}>
          Go back home
        </Button>
      </VStack>
    </Center>
  );
};

export default NotFoundPage;
