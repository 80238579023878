export enum PageMode {
  Create = 'Create',
  Edit = 'Edit',
}

export interface OrganizationForm {
  organizationID?: string;
  name: string;
  description: string;
}
