import {
  Box,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
  Avatar,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { WorkspaceTable } from '../Workspace/WorkspaceTable';

import CreateOrganization from './Configure/CreateOrganization';

import { useContext } from 'react';
import { ApplicationStoreContext } from '../../hooks/useApplicationStore';
import { PageMode } from './model';
import EditOrganization from './Configure/EditOrganization';
import ManageMembers from './Configure/ManageMembers';

const OrganizationPage = ({ mode }: { mode: PageMode }) => {
  const { applicationStore } = useContext(ApplicationStoreContext);

  return (
    <Box mx='auto' h='100%' bgColor='white' padding='1rem'>
      <Flex alignItems='center' px='1rem' py='1rem'>
        <Avatar
          size='xl'
          name='Workspace Image'
          src='https://ui-avatars.com/api/?name=ORG&background=random'
        />
        <Box ml={4}>
          <Heading size='lg'>Organization</Heading>
        </Box>
      </Flex>
      <Tabs variant='enclosed' maxW={'40rem'}>
        <TabList>
          <Tab>Configure</Tab>
          <Tab isDisabled={mode === PageMode.Create}>Manage workspaces</Tab>
          <Tab isDisabled={mode === PageMode.Create}>Manage members</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {mode === PageMode.Create && (
              <CreateOrganization store={applicationStore} />
            )}
            {mode !== PageMode.Create && (
              <EditOrganization store={applicationStore} />
            )}
          </TabPanel>
          <TabPanel>{mode !== PageMode.Create && <WorkspaceTable />}</TabPanel>
          <TabPanel>{mode !== PageMode.Create && <ManageMembers />}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default OrganizationPage;
