import * as React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Image,
  AspectRatio,
} from "@chakra-ui/react";

const featureList = [
  {
    title: "Project Management",
    description:
      "Our platform provides a centralized location for developers to manage their projects, including task tracking, team collaboration, and project milestones.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Asset Management",
    description:
      "Easily store and manage all your game assets in one place. Our platform makes it easy to organize, track, and share your assets with your team.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Code Collaboration",
    description:
      "With our integrated code editor and version control system, developers can work together seamlessly, ensuring that code changes are tracked and managed effectively.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Analytics",
    description:
      "Our platform provides detailed analytics and reporting tools, giving developers the ability to track user engagement and identify areas for improvement.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Publishing and Distribution",
    description:
      "Our platform streamlines the publishing and distribution process, enabling developers to release their games to multiple platforms with ease.",
    imgSrc: "https://via.placeholder.com/150",
  },
  {
    title: "Community Support",
    description:
      "Our platform offers a community of game developers, providing a platform for developers to connect, share knowledge, and collaborate on projects.",
    imgSrc: "https://via.placeholder.com/150",
  },
];

const About: React.FC = () => {
  return (
    <Container maxW="container.xl">
      <Box mt={10} textAlign="center">
        <Heading mb={6}>Welcome to GameForgeLabs</Heading>
        <Text mb={6}>
          The all-in-one platform designed to streamline your game development
          workflow. We provide a comprehensive suite of tools and features that
          enable developers to manage and collaborate on their projects
          seamlessly.
        </Text>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} mt={10}>
        {featureList.map((feature, index) => (
          <VStack
            key={index}
            boxShadow="lg"
            borderRadius="md"
            overflow="hidden"
            p={6}
            bgColor="white"
            spacing={6}
            _hover={{ boxShadow: "xl" }}
          >
            <AspectRatio ratio={4 / 3}>
              <Image src={feature.imgSrc} alt={feature.title} />
            </AspectRatio>
            <Heading size="md">{feature.title}</Heading>
            <Text>{feature.description}</Text>
          </VStack>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default About;
