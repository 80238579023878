import React, { MouseEventHandler, useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Box,
  Spinner,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { AUTH_ERROR_MAPPING } from '../../models/auth/AuthError';
import { IError } from '../../models/error-handling/IError';
import { IAuthService } from '../../services/IAuthenticationService';

interface SignUpProps {
  authService: IAuthService;
}

const SignUp: React.FC<SignUpProps> = ({ authService }: SignUpProps) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setUsernameError(validateUsername(username));
    setEmailError(validateEmail(email));
    setApiError('');
    setPasswordError(password ? '' : 'Password is required');
    setConfirmPasswordError(
      confirmPassword ? '' : 'Confirm Password is required',
    );

    if (
      password !== null &&
      confirmPassword !== null &&
      password !== confirmPassword
    ) {
      setIsFormValid(false);
      setConfirmPasswordError("The passwords don't match");
      return;
    }

    if (
      username &&
      email &&
      password &&
      confirmPassword &&
      !emailError &&
      !usernameError
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [username, email, password, confirmPassword, emailError, usernameError]);

  function validateEmail(email: string): string {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!email) {
      return 'Email is required';
    } else if (!regex.test(email)) {
      return 'Invalid email format';
    } else {
      return '';
    }
  }

  function validateUsername(username: string): string {
    const regex = /^[a-zA-Z0-9_-]+$/;
    if (!username) {
      return 'Username is required';
    } else if (username.length < 4) {
      return 'Username must be at least 4 characters long';
    } else if (/\s/.test(username)) {
      return 'Username must not contain spaces';
    } else if (!regex.test(username)) {
      return 'Username contains invalid characters';
    } else {
      return '';
    }
  }

  function navigateToLogin(): MouseEventHandler<HTMLButtonElement> | undefined {
    navigate('/login/signin');
    return undefined;
  }

  function createAccount(): void {
    setApiError('');
    setIsSubmitting(true);
    authService.createUserWithEmailAndPassword({
      email,
      password,
      username,
      onSuccess: () => {
        setIsSubmitting(false);
        navigate('/builds');
      },
      onError: (error: IError) => {
        const userFriendlymessage =
          AUTH_ERROR_MAPPING[error.code] || 'An unexpected error occurred.';
        setApiError(userFriendlymessage);
        setIsSubmitting(false);
      },
    });
  }

  return (
    <>
      <FormControl isInvalid={Boolean(usernameError)}>
        <FormLabel>Username</FormLabel>
        <Input
          type='text'
          placeholder='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormErrorMessage>{usernameError}</FormErrorMessage>
      </FormControl>
      <FormControl mt={4} isInvalid={Boolean(emailError)}>
        <FormLabel>Email</FormLabel>
        <Input
          type='email'
          placeholder='Email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormErrorMessage>{emailError}</FormErrorMessage>
      </FormControl>
      <FormControl mt={4} isInvalid={Boolean(passwordError)}>
        <FormLabel>Password</FormLabel>
        <Input
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormErrorMessage>{passwordError}</FormErrorMessage>
      </FormControl>
      <FormControl mt={4} isInvalid={Boolean(confirmPasswordError)}>
        <FormLabel>Confirm Password</FormLabel>
        <Input
          type='password'
          placeholder='Confirm Password'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
      </FormControl>
      {apiError && (
        <Stack spacing={6} mt={6}>
          (
          <Alert status='error' mb={4}>
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>{apiError}</AlertDescription>
          </Alert>
          )
        </Stack>
      )}
      <Stack spacing={6} mt={6}>
        {isSubmitting ? (
          <Box display='flex' alignItems='center'>
            <Spinner size='xs' marginRight='2' />
            Creating account...
          </Box>
        ) : (
          <Button
            colorScheme='blue'
            type='submit'
            isDisabled={!isFormValid}
            onClick={createAccount}
          >
            Create account
          </Button>
        )}
      </Stack>
      <Text textAlign='center' mt={6}>
        Already have an account?{' '}
        <Text
          as='a'
          href='#'
          onClick={() => navigateToLogin()}
          color={useColorModeValue('blue.500', 'blue.200')}
          fontWeight='bold'
        >
          Login
        </Text>
      </Text>
    </>
  );
};

export default SignUp;
