import * as React from "react";

import {
  Heading,
  Flex,
  Box,
} from "@chakra-ui/react";
import BuildList from "../../components/BuildList/BuildList";
import CustomAvatar from "../../components/Avatar/Avatar";

interface BuildsProps {}



export const Builds: React.FC<BuildsProps> = (props) => {
  return (
    <Box width="100%">
      <Flex alignItems="center" px="1rem" py="1rem">
        <CustomAvatar name="test" width="100px"/>
        <Box ml={4}>
          <Heading size="lg">
            Latest builds
          </Heading>
        </Box>
      </Flex>
      <BuildList />
    </Box>
  );
};
