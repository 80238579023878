import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Button,
  SimpleGrid,
  Container,
  chakra,
  Stack,
  Icon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  FaGooglePlay,
  FaAppStore,
  FaLock,
  FaCubes,
  FaShieldAlt,
  FaShareAlt,
} from 'react-icons/fa';

interface FeatureCardProps {
  title: string;
  description: string;
  icon?: React.ReactElement;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  icon,
}) => {
  const bg = useColorModeValue('white', 'gray.700');
  const color = useColorModeValue('gray.800', 'gray.100');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.600');

  return (
    <chakra.div
      role='group'
      bg={bg}
      color={color}
      borderRadius='md'
      shadow='md'
      borderWidth='1px'
      borderColor={borderColor}
      p={6}
      textAlign='center'
      _hover={{ bg: hoverBg, cursor: 'pointer' }}
      transition='background 0.2s ease-in-out'
    >
      <VStack spacing={4}>
        {icon}
        <Heading size='md'>{title}</Heading>
        <Text fontSize='md'>{description}</Text>
      </VStack>
    </chakra.div>
  );
};

const MainPage: React.FC = () => {
  const customButtonColor = useColorModeValue('teal', 'green');
  const color = useColorModeValue('gray.800', 'gray.100');
  const navigate = useNavigate();

  function navigateToLogin(): void {
    navigate('/login/signin');
  }

  return (
    <Box>
      <Container maxW='container.xl'>
        <Flex
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          pt={12}
        >
          <Stack direction='row' align='center' spacing={8} mt={8}>
            <Flex direction='column' align='center'>
              <Icon as={FaGooglePlay} boxSize={8} color='green.500' />
              <Text fontWeight='bold' mt={2}>
                Android Builds
              </Text>
              <Text fontSize='sm' color='gray.500'>
                Manage and download game builds
              </Text>
            </Flex>

            <Flex direction='column' align='center'>
              <Icon as={FaAppStore} boxSize={8} color='blue.500' />
              <Text fontWeight='bold' mt={2}>
                iOS Builds
              </Text>
              <Text fontSize='sm' color='gray.500'>
                Manage and download game builds
              </Text>
            </Flex>

            <Flex direction='column' align='center'>
              <Icon as={FaLock} boxSize={8} color='purple.500' />
              <Text fontWeight='bold' mt={2}>
                Access Control
              </Text>
              <Text fontSize='sm' color='gray.500'>
                Control access with IAM
              </Text>
            </Flex>
          </Stack>
          <VStack spacing={4} textAlign='center' my={12}>
            <Heading size='2xl' color={color}>
              Store, share, and control access to software artifacts
            </Heading>
            <Text fontSize='xl' color={color}>
              a unique platform to store, share, and regulate access to software
              artifacts
            </Text>
            <Button
              colorScheme={customButtonColor}
              size='lg'
              onClick={navigateToLogin}
            >
              Get Started
            </Button>
          </VStack>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} my={12}>
            <FeatureCard
              title='Easy Sharing'
              description='Share your game builds with your team instantly, streamlining collaboration and boosting productivity.'
              icon={<Icon as={FaShareAlt} boxSize={8} color='purple.500' />}
            />
            <FeatureCard
              title='Access Control'
              description='Leverage Identity and Access Management (IAM) features to manage permissions and control access to your game builds. Safeguard your game builds with our advanced security features, ensuring that your data remains protected.'
              icon={<Icon as={FaShieldAlt} boxSize={8} color='blue.500' />}
            />
            <FeatureCard
              title='Release to Application Stores'
              description='Seamlessly release your game builds to Amazon, Google Play, Samsung, and Windows stores, saving time and effort.'
              icon={<Icon as={FaCubes} boxSize={8} color='green.500' />}
            />
          </SimpleGrid>
          {/*
          <BillingModel
            plans={[
              {
                title: 'Basic',
                description: 'A great starting point for small teams.',
                price: 1,
                features: [
                  '25 GB storage',
                  'Basic access control',
                  '5 workspaces',
                  'Email support',
                ],
              },
              {
                title: 'Pro',
                description: 'Ideal for growing teams and projects.',
                price: 30,
                features: [
                  '100 GB storage',
                  'Advanced access control',
                  'Priority email support',
                ],
              },
              {
                title: 'Enterprise',
                description: 'For organizations with complex needs.',
                price: 150,
                features: [
                  'Unlimited storage',
                  'Custom access control',
                  'Login with SSO',
                  'Dedicated support',
                ],
              },
            ]}
          />*/}
        </Flex>
      </Container>
    </Box>
  );
};

export default MainPage;
