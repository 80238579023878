import { useMemo } from 'react';
import { FirebaseAuthService } from '../services/FirebaseAuthService';
import Firebase from '../services/Firebase';
import { IAuthService } from '../services/IAuthenticationService';
import AppClientService from '../services/AppClientService';

const firebase = new Firebase();
firebase.initializeFirebase();
const authService = new FirebaseAuthService(
  firebase,
  new AppClientService(),
) as IAuthService;
export default () => useMemo(() => authService, []);
