import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Spinner,
} from "@chakra-ui/react";


import { AUTH_ERROR_MAPPING } from "../../models/auth/AuthError";
import useAuth from "../../hooks/useAuth";
import { WarningType } from "../../models/error-handling/WarningType";
import { UserProfile } from "../../models/auth/UserProfile";
import { FormWarning } from "../../models/error-handling/FormWarning";

interface UserProfileConfigProps {
  
}

const UserProfileConfig: React.FC<UserProfileConfigProps> = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const authService = useAuth();
  const [apiError, setApiError] = useState<FormWarning | null>();
  const [isApiCallInProgress, setApiCallInProgress] = useState(false);

  const { email, displayName } = authService.getState().user!;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newPassword.length < 6 || confirmPassword.length < 6) {
      setApiError({
        message: "Password must have at least 6 characters",
        status: WarningType.ERROR,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      setApiError({
        message: "Passwords do not match.",
        status: WarningType.ERROR,
      });
      return;
    }

    setApiCallInProgress(true);
    authService.updatePassword({
      currentPassword,
      newPassword,
      onSuccess: () => {
        setApiError({
          message: "Password has been updated successfully.",
          status: WarningType.INFO,
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setApiCallInProgress(false);
      },
      onError: (error) => {
        const userFriendlyMessage =
          AUTH_ERROR_MAPPING[error.code] || "An unexpected error occurred.";
        setApiError({
          message: userFriendlyMessage,
          status: WarningType.ERROR,
          type: "",
        });
        setApiCallInProgress(false);
      },
    });
  };

  return (
    <>
      <Box mx="auto" bgColor="white" padding="1rem">
        <Tabs variant="enclosed" maxW={"50rem"}>
          <TabList>
            <Tab>Profile</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    value={displayName}
                    isReadOnly
                    isDisabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={email} isReadOnly isDisabled />
                </FormControl>
                <FormControl>
                  <FormLabel>Current password</FormLabel>
                  <Input
                    type="password"
                    value={currentPassword}
                    onChange={(event) => setCurrentPassword(event.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>New password</FormLabel>
                  <Input
                    type="password"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Confirm new password</FormLabel>
                  <Input
                    type="password"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </FormControl>
                {apiError && (
                  <Alert status={apiError.status}>
                    <AlertIcon />
                    <AlertTitle mr={2}>Info</AlertTitle>
                    <AlertDescription>{apiError.message}</AlertDescription>
                  </Alert>
                )}

                <Button colorScheme="blue" onClick={handleSubmit}>
                  {isApiCallInProgress ? (
                    <Spinner size="sm" />
                  ) : (
                    "Update password"
                  )}
                </Button>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default UserProfileConfig;
