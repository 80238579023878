import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Stack,
  IconButton,
  InputGroup,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tr,
} from '@chakra-ui/react';
import useAuth from '../../../hooks/useAuth';
import { FormWarning } from '../../../models/error-handling/FormWarning';
import { WarningType } from '../../../models/error-handling/WarningType';
import { Link, useParams } from 'react-router-dom';
import {
  OrganizationMemberDTO,
  OrganizationPermission,
} from '../../../types/app-store';
import { DeleteIcon } from '@chakra-ui/icons';
import useAppClient from '../../../hooks/useAppClient';

const ManageMembers = () => {
  const authService = useAuth();
  const currentUserEmail = authService.getState().user?.email;
  const appClientService = useAppClient();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<FormWarning | null>(null);
  const [isOrganizationAdmin, setOrganizationAdmin] = useState(false);
  const [members, setMembers] = useState<OrganizationMemberDTO[]>([]);
  const [role, setRole] = useState('MEMBER');
  const [email, setEmail] = useState('');

  const { organizationId } = useParams();
  console.log(organizationId);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim());
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
    setRole(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    setApiError(null);

    async function fetchOrganizationMembers(organizationID: number) {
      const members = await appClientService.getOrganizationMembers(
        organizationID,
      );
      return members;
    }

    try {
      if (organizationId) {
        fetchOrganizationMembers(Number(organizationId)).then((members) => {
          setMembers(members);
          const isOrgAdmin = members.filter(
            (member) =>
              member.email === currentUserEmail &&
              member.permission === 'OWNER',
          );
          setOrganizationAdmin(isOrgAdmin.length > 0);
        });
      }
    } catch (err: any) {
      setApiError({
        message:
          err.detail ||
          'Could not load organization members due to internal server error',
        status: WarningType.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  async function addMember() {
    if (organizationId) {
      const members = await appClientService.addOrganizationMember(
        Number(organizationId),
        {
          email,
          permission: role as OrganizationPermission,
        },
      );
      setMembers(members);
    }
  }

  async function expelMember(member: OrganizationMemberDTO) {
    if (organizationId) {
      const members = await appClientService.expelOrganizationMember(
        Number(organizationId),
        member.id,
      );
      setMembers(members);
    }
  }

  return (
    <form>
      <VStack spacing={4} width='100%' align='stretch'>
        <FormControl pb='1rem'>
          <FormLabel htmlFor='email'>Invite Users</FormLabel>
          <InputGroup>
            <Input
              id='email'
              placeholder="Enter user's email address"
              onChange={handleEmailChange}
            />
            <Select id='role' ml={2} onChange={handleRoleChange}>
              <option value='MEMBER'>Member</option>
              <option value='OWNER'>Owner</option>
            </Select>
            <Button
              ml={2}
              p={5}
              size='sm'
              onClick={addMember}
              isDisabled={!isOrganizationAdmin}
            >
              Add
            </Button>
          </InputGroup>
        </FormControl>
        <FormLabel>Member:</FormLabel>
        <VStack spacing={2} align='stretch'>
          <TableContainer>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th>User / Group</Th>
                  <Th>Type</Th>
                  <Th>Permission</Th>
                </Tr>
              </Thead>
              <Tbody>
                {members.map((member) => (
                  <Tr>
                    <Td>{member.email}</Td>
                    <Td>{member.type}</Td>
                    <Td>{member.permission}</Td>
                    <Td>
                      {' '}
                      <IconButton
                        isDisabled={
                          member.permission === 'OWNER' || !isOrganizationAdmin
                        }
                        onClick={() => expelMember(member)}
                        marginLeft='0.5rem'
                        aria-label='Configure user'
                        icon={<DeleteIcon />}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>

        {apiError && (
          <Stack width='100%'>
            (
            <Alert status={apiError.status} data-testid='login-alert-message'>
              <AlertIcon />
              <AlertTitle>Info</AlertTitle>
              <AlertDescription>
                <div>
                  {apiError.message}
                  {apiError.status !== WarningType.ERROR && (
                    <div>
                      Click{' '}
                      <Text fontWeight='bold'>
                        <Link to='/dashboard'>here</Link>
                      </Text>{' '}
                      to the organization
                    </div>
                  )}
                </div>
              </AlertDescription>
            </Alert>
            )
          </Stack>
        )}
      </VStack>
    </form>
  );
};

export default ManageMembers;
