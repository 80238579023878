import React, { ReactNode, useEffect, useState } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Link as ChakraLink,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import {
  FiHome,
  FiSettings,
  FiMenu,
  FiChevronDown,
  FiCopy,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightIcon, SearchIcon } from '@chakra-ui/icons';
import { AuthState } from '../../models/auth/AuthState';
import { AuthStateService } from '../../services/AuthStateService';
import { IAuthService } from '../../services/IAuthenticationService';

interface LinkItemProps {
  name: string;
  dataTestId: string;
  icon?: IconType;
  route?: string;
  role?: string;
  element?: any;
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Dashboard',
    icon: FiHome,
    route: '/dashboard',
    dataTestId: 'menu-dashboard',
  },
  {
    name: 'Organization',
    icon: FiSettings,
    route: '/organization',
    dataTestId: 'menu-organization',
  },
  {
    name: 'Workspace',
    icon: FiSettings,
    route: '/workspace',
    dataTestId: 'menu-workspace',
  },
  {
    name: 'Application Management',
    icon: FiSettings,
    route: '/organization',
    role: 'ADMIN',
    dataTestId: 'menu-application-management',
  },
];

const DevLinkItems: Array<LinkItemProps> = [
  {
    name: 'Copy Token',
    element: (
      <Text
        onClick={() => {
          const token = AuthStateService.readAuthToken();
          navigator.clipboard.writeText(token.user?.idToken || '');
        }}
      >
        Copy token
      </Text>
    ),
    icon: FiCopy,
    dataTestId: 'menu-copy-token',
  },
];

export default function SidebarWithHeader({
  children,
  authService,
}: {
  children: ReactNode;
  authService: IAuthService;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const color = useColorModeValue('gray.100', 'gray.900');

  const [authenticated, setAuthenthicated] = useState(
    authService.isAuthenticated(),
  );

  const loggedIn = (state: AuthState) => {
    setAuthenthicated(state.authenticated);
  };

  useEffect(() => {
    authService.subscribe(loggedIn);
    return () => authService.unsubscribe(loggedIn);
  });

  return (
    <Box minH='80vh' bg={color}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        authenticated={authenticated}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} authenticated={authenticated} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} authService={authService} />
      <Box ml={{ base: 0, md: authenticated ? 60 : 0 }}>{children}</Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  authenticated: boolean;
}

const SidebarContent = ({ onClose, authenticated, ...rest }: SidebarProps) => {
  const navigate = useNavigate();
  return (
    <Box
      bg={useColorModeValue('gray.800', 'gray.900')}
      borderRight='1px'
      color='white'
      borderRightColor={useColorModeValue('gray.800', 'gray.900')}
      w={{ base: '0', md: !authenticated ? 'full' : 60 }}
      pos='fixed'
      h={authenticated ? 'full' : 'auto'}
      {...rest}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between'>
        <Link to='/'>
          <Text fontSize='2xl' m={0} p={0} position='relative' left={-15}>
            <ArrowRightIcon /> GameForge
          </Text>
        </Link>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {authenticated &&
        LinkItems.map(
          (link) =>
            link.icon && (
              <NavItem
                datatest-id={link.dataTestId}
                key={link.name}
                icon={link.icon}
                onClick={() => link.route && navigate(link.route)}
              >
                {link.name}
              </NavItem>
            ),
        )}
      {authenticated &&
        DevLinkItems.map(
          (link) =>
            link.icon && (
              <NavItem
                key={link.name}
                icon={link.icon}
                onClick={() => {}}
                datatest-id={link.dataTestId}
              >
                {link.element}
              </NavItem>
            ),
        )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <ChakraLink
      href='#'
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align='center'
        p='2'
        mx='2'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'green.600',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr='4'
            fontSize='16'
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </ChakraLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  authService: IAuthService;
}
const MobileNav = ({ onOpen, authService, ...rest }: MobileProps) => {
  const authenticated = authService.isAuthenticated();
  const user = authService.getState().user;
  const navigate = useNavigate();

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      {authenticated && (
        <Box width='100%'>
          <InputGroup maxWidth='25rem'>
            <InputLeftElement pointerEvents='none' children={<SearchIcon />} />
            <Input placeholder='Search for builds, platform, architecture, tags...' />
          </InputGroup>
        </Box>
      )}
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        icon={<FiMenu />}
      />
      <Link to='/'>
        <Text
          display={{ base: 'flex', md: 'none' }}
          fontSize='2xl'
          fontWeight='bold'
        >
          GameForge Labs.
        </Text>
      </Link>

      {authenticated && (
        <HStack spacing={{ base: '0', md: '6' }}>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                _focus={{ boxShadow: 'none' }}
                datatest-id='avatar'
              >
                <HStack>
                  <Avatar
                    datatest-id='avatar-img'
                    size={'md'}
                    src={`https://ui-avatars.com/api/?name=${
                      user?.displayName || 'user'
                    }`}
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems='flex-start'
                    spacing='1px'
                    ml='2'
                  >
                    {user && (
                      <Text fontSize='sm' datatest-id='avatar-display-name'>
                        {user.displayName}
                      </Text>
                    )}
                    {user && (
                      <Text
                        fontSize='xs'
                        color='red.600'
                        datatest-id='avatar-role'
                      >
                        {user.role}
                      </Text>
                    )}
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList>
                <Link to='/profile'>
                  {' '}
                  <MenuItem datatest-id='menu-settings-link'>Settings</MenuItem>
                </Link>

                <Link to='/workspace?create=true'>
                  <MenuItem datatest-id='menu-create-workspace-link'>
                    Create workspace
                  </MenuItem>
                </Link>

                <MenuDivider />
                <MenuItem
                  datatest-id='menu-create-signout-link'
                  onClick={() => {
                    authService.signOut().finally(() => navigate('/'));
                  }}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      )}
    </Flex>
  );
};
