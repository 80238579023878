import { ChakraProvider, theme } from '@chakra-ui/react';
import Login from './pages/Login/LoginPage';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import Footer from './components/Footer/Footer';
import { Builds } from './pages/Builds/Builds';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import WorkspaceForm from './pages/Workspace/Workspace';
import Profile from './pages/Profile/Profile';
import NotFoundPage from './components/404/404';
import { useEffect } from 'react';
import SideBar from './components/SideBar/SideBar';
import { ReleasedBuilds } from './pages/ReleasedBuilds/ReleasedBuilds';
import OrganizationPage from './pages/Organization/Organization';
import useAuth from './hooks/useAuth';
import { Provider } from 'mobx-react';
import { ApplicationStoreContext } from './hooks/useApplicationStore';
import Dashboard from './pages/Dashboard/Dashboard';
import { CookiePolicyModal } from './components/CookiePolicy/CookiePolicy';
import { PageMode } from './pages/Organization/model';

const customTheme = {
  ...theme,
};

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  const authService = useAuth();

  if (!authService.getState().authenticated) {
    return <Navigate to='/login/signin' state={{ from: location }} replace />;
  }

  return children;
}

function CheckLogin({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const authService = useAuth();

  if (authService.getState().authenticated) {
    return <Navigate to='/builds' state={{ from: location }} replace />;
  }

  return children;
}

const Layout = () => {
  const authService = useAuth();
  useEffect(() => {
    authService.setupEventHandlers();
    return () => {
      authService.removeEventHandlers();
    };
  }, [authService]);

  return (
    <>
      <SideBar authService={authService}>
        <Outlet />
      </SideBar>
      <Footer />
      <CookiePolicyModal />
    </>
  );
};

const routers = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: (
          <CheckLogin>
            <Home />
          </CheckLogin>
        ),
      },
      {
        path: '/login/:page',
        element: (
          <CheckLogin>
            <Login />
          </CheckLogin>
        ),
      },
      {
        path: '/dashboard',
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        ),
      },
      {
        path: '/builds',
        element: (
          <RequireAuth>
            <Builds />
          </RequireAuth>
        ),
      },
      {
        path: '/builds/release',
        element: (
          <RequireAuth>
            <ReleasedBuilds />
          </RequireAuth>
        ),
      },
      {
        path: '/workspace/:workspaceId?',
        element: (
          <RequireAuth>
            <WorkspaceForm />
          </RequireAuth>
        ),
      },
      {
        path: '/organization/:organizationId',
        element: (
          <RequireAuth>
            <OrganizationPage mode={PageMode.Edit} />
          </RequireAuth>
        ),
      },
      {
        path: '/organization/create',
        element: (
          <RequireAuth>
            <OrganizationPage mode={PageMode.Create} />
          </RequireAuth>
        ),
      },
      {
        path: '/profile',
        element: (
          <RequireAuth>
            <Profile />
          </RequireAuth>
        ),
      },
      {
        path: '/about',
        element: <About />,
      },
    ],
  },
]);

export const App = () => (
  <ChakraProvider theme={customTheme}>
    <Provider {...ApplicationStoreContext}>
      <RouterProvider router={routers} />
    </Provider>
  </ChakraProvider>
);
