import React from "react";
import macOsImg from "./img/MacOS.png";
import iosImg from "./img/iOS.png";
import windowsImg from "./img/Windows.png";
import androidImg from "./img/Android.png";
import html5Img from "./img/Html5.png";
import linuxImg from "./img/Linux.png";

interface PlatformIconProps {
  name: string;
}

const PlatformIcon: React.FC<PlatformIconProps> = (
  props: PlatformIconProps
) => {
  const resolveIcon = (platform: string): string => {
    if (platform.includes("mac")) {
      return macOsImg;
    }
    if (platform.includes("ios")) {
      return iosImg;
    }
    if (platform.includes("windows")) {
      return windowsImg;
    }
    if (platform.includes("android")) {
      return androidImg;
    }
    if (platform.includes("html5")) {
      return html5Img;
    }
    if (platform.includes("linux")) {
      return linuxImg;
    }
    return "";
  };

  return <img src={resolveIcon(props.name)} alt="Platform Icon" width={40} />;
};

export default PlatformIcon;
