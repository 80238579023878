import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  Center,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import QRCode from "qrcode.react";

interface QRCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  value: string;
}

function QRCodeModal({ isOpen, onClose, value }: QRCodeModalProps) {
  const textColor = useColorModeValue("gray.700", "gray.300");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={textColor}>Download Game Build</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Text color={textColor}>
              Scan the QR code below to download the game build onto your mobile
              device. This QR code contains the link to the APK file which can
              be installed on Android devices.
            </Text>
            <Center>
              <QRCode value={value} size={200} />
            </Center>
            <Text color={textColor}>
              Alternatively, you can manually download the game build by
              visiting the following link:
            </Text>
            <Text as="a" href={value} color="blue.500">
              {value}
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function QRCodeDownload() {
  const [isOpen, setIsOpen] = useState(false);
  const buttonBg = useColorModeValue("blue.500", "blue.300");
  const buttonText = useColorModeValue("white", "gray.800");

  function toggleModal() {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }

  return (
    <>
      <Button
        ml="0.5rem"
        p="1rem"
        onClick={toggleModal}
        size="sm"
        bg={buttonBg}
        color={buttonText}
        _hover={{ boxShadow: "md" }}
      >
        Get QR Code
      </Button>
      <QRCodeModal
        isOpen={isOpen}
        onClose={toggleModal}
        value="https://example.com"
      />
    </>
  );
}

export default QRCodeDownload;
