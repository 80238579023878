import React from 'react';
import { Image } from '@chakra-ui/react';

interface Props {
  name: string;
  width?: string;
}

function CustomAvatar({ name, width }: Props): JSX.Element {
  return (
    <Image
      width={width || "100%"}
      p={4}
      src={`https://ui-avatars.com/api/?name=${name}&background=random`}
      alt={name}
    />
  );
}

export default CustomAvatar;
