import { User } from '../models/auth/User';
import { AuthState } from '../models/auth/AuthState';

export class AuthStateService {
  protected authState: AuthState;
  protected subscribers: Array<(state: AuthState) => void> = [];
  protected static AUTH_EVENT = 'customAuth';

  constructor() {
    const authObject = localStorage.getItem('auth');
    if (authObject) {
      this.authState = JSON.parse(authObject);
    } else {
      this.authState = {
        authenticated: false,
      };
    }
  }

  static readAuthToken(): AuthState {
    const authObject = localStorage.getItem('auth');
    if (authObject) {
      const state = JSON.parse(authObject);
      return state;
    }
    throw new Error('Cannot read auth state');
  }

  subscribe(callback: (state: AuthState) => void): void {
    this.subscribers.push(callback);
  }

  unsubscribe(callback: (state: AuthState) => void): void {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== callback,
    );
  }

  setAuthenticated(authenticated: boolean): void {
    this.authState.authenticated = authenticated;
    this.notifySubscribers();
    localStorage.removeItem('auth');
  }

  setupEventHandlers(): void {
    window.addEventListener('storage', this.handleStorage);
    window.addEventListener(
      AuthStateService.AUTH_EVENT,
      this.handleCustomAuthEvent,
    );
  }

  removeEventHandlers(): void {
    window.removeEventListener('storage', this.handleStorage);
    window.removeEventListener(
      AuthStateService.AUTH_EVENT,
      this.handleCustomAuthEvent,
    );
  }

  signIn(user: User): void {
    this.authState = {
      ...this.authState,
      authenticated: true,
      user: {
        ...user,
      },
    };
    localStorage.setItem('auth', JSON.stringify(this.authState));
    window.dispatchEvent(new CustomEvent('customAuth', {}));
  }

  isAuthenticated(): boolean {
    return this.authState.authenticated;
  }

  getState(): AuthState {
    return this.authState;
  }

  private notifySubscribers(): void {
    for (const subscriber of this.subscribers) {
      subscriber(this.authState);
    }
  }

  private handleStorage = (event: StorageEvent): void => {
    if (event.key === 'auth') {
      this.authState.authenticated = true;
      this.notifySubscribers();
    }
  };

  private handleCustomAuthEvent = (event: any): void => {
    this.authState.authenticated = true;
    this.notifySubscribers();
  };
}
