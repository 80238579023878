import * as React from "react";

import {
  Image,
  Badge,
  Text,
  Box,
  Heading,
  SimpleGrid,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import QRCodeModal from "../../components/QRCodeModal/QRCodeModal";
import { CheckIcon, SettingsIcon } from "@chakra-ui/icons";
import CustomAvatar from "../../components/Avatar/Avatar";

interface BuildsProps {}

interface GameData {
  game: string;
  version: string;
  buildId: string;
  appStore: string;
  author: string;
  timestamp: string;
}

const FeaturedGameCard: React.FC<GameData> = ({
  game,
  version,
  buildId,
  appStore,
  author,
  timestamp,
}) => {
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const accentColor = useColorModeValue("blue.500", "blue.300");

  return (
    <Box
      borderWidth={1}
      borderColor={borderColor}
      _hover={{ bg: hoverBg, cursor: "pointer" }}
      transition="background 0.2s ease-in-out"
      boxShadow="md"
      padding="0.5rem"
      bgColor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      borderRadius="2xl"
    >
      <CustomAvatar name="50px" width="50px"/>
      <Text fontSize="lg" fontWeight="semibold" color={textColor}>
        {game}
      </Text>
      <Text fontSize="sm" color={textColor} mt={1}>
        Build id: {buildId}
      </Text>
      <Text fontSize="sm" color={accentColor} mt={1}>
        Released on: {appStore}
      </Text>
      <Badge
        colorScheme="blue"
        fontSize="sm"
        fontWeight="normal"
        mt={2}
        py={1}
        px={2}
      >
        {version}
      </Badge>
      <Text fontSize="xs" color={textColor} mt={2} fontWeight="bold">
        Author: {author}
      </Text>
      <Text fontSize="xs" color={textColor} mt={1}>
        {timestamp}
      </Text>
      <QRCodeModal />
      <Button colorScheme="blue" size="sm" mt="0.5rem">
        <Box as={SettingsIcon} mr="0.25rem" />
        Edit
      </Button>
      <Button colorScheme="green" size="sm" mt="0.5rem">
        <Box as={CheckIcon} mr="0.25rem" />
        Publish
      </Button>
    </Box>
  );
};

export default FeaturedGameCard;

const FeaturedGames: React.FC<{ gameData: GameData[] }> = ({ gameData }) => {
  return (
    <SimpleGrid columns={[1, 2, 4, 6, 8]} spacing={4}>
      {gameData.map((game, index) => (
        <FeaturedGameCard key={index} {...game} />
      ))}
    </SimpleGrid>
  );
};

export const ReleasedBuilds: React.FC<BuildsProps> = (props) => {
  const gameData: GameData[] = [
    {
      game: "Pokemon Go",
      appStore: "Apple App Store",
      version: "v1.2.5",
      buildId: "3d44a8c7f224fecc92d27cc12f20e4ea1",
      author: "Fred Maria",
      timestamp: "2023-04-25 12:30:00",
    },
    {
      game: "Call of Duty Mobile",
      appStore: "Google Play App Store",
      version: "v0.9.3",
      buildId: "3d44a8c7f224fecc92d27cc12f20e4ea",
      author: "Kiss Now",
      timestamp: "2023-04-25 12:30:00",
    },
    {
      game: "Minecraft",
      appStore: "SAMSUNG App Store",
      version: "v1.1.0",
      buildId: "3d44a8c7f224fecc92d27cc12f20e4ea",
      author: "Whos Daddy",
      timestamp: "2023-04-25 12:30:00",
    },
    {
      game: "MapleStory M - Live",
      appStore: "Amazon App Store",
      version: "v0.8.1",
      buildId: "3d44a8c7f224fecc92d27cc12f20e4ea",
      author: "John Smith",
      timestamp: "2023-04-25 12:30:00",
    },
    {
      game: "MapleStory M - QA",
      appStore: "Amazon App Store",
      version: "v0.8.2",
      buildId: "3d44a8c7f224fecc92d27cc12f20e4ea",
      author: "John Smith",
      timestamp: "2023-04-24 12:30:00",
    },
    {
      game: "MapleStory M - Feat",
      appStore: "Amazon App Store",
      version: "v0.8.0",
      buildId: "3d44a8c7f224fecc92d27cc12f20e4ea",
      author: "John Smith",
      timestamp: "2023-04-23 12:30:00",
    },
  ];

  return (
    <Box width="100%">
      <Heading as="h2" size="lg" p="1rem">
        Released builds:
      </Heading>
      <Box ml="1rem" mr="1rem">
        <FeaturedGames gameData={gameData} />
      </Box>
    </Box>
  );
};
