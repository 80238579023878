import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Stack,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Workspace from '../../models/organization/Workspace';
import { Link } from 'react-router-dom';

interface WorkspaceTableProps {}

const workspaces = [
  {
    name: 'Dungeon Crawler',
    description:
      'A workspace for developing an RPG game about exploring dungeons and fighting monsters.',
  },
  {
    name: 'Space Adventure',
    description:
      'A workspace for creating a sci-fi game where players explore space and interact with alien species.',
  },
  {
    name: 'Fantasy Quest',
    description:
      'A workspace for developing a fantasy adventure game with quests, magic, and mythical creatures.',
  },
  {
    name: 'Zombie Apocalypse',
    description:
      'A workspace for creating a survival game set in a post-apocalyptic world overrun by zombies.',
  },
];

export const WorkspaceTable: React.FC<WorkspaceTableProps> = ({}) => {
  return (
    <Box>
      <Stack>
        <Box>
          <Link to='/workspace'>
            <Button mb='1rem'>Add new workspace</Button>
          </Link>
        </Box>
      </Stack>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {workspaces.map((workspace, index) => (
            <Tr key={index}>
              <Td>{workspace.name}</Td>
              <Td>{workspace.description}</Td>
              <Td>
                <Button colorScheme='teal' size='sm' leftIcon={<EditIcon />}>
                  Edit
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
