/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2023-05-14 20:49:36.

/**
 * Details about an API error following the RFC 7807 standard.
 */
export interface ApiError {
    type: string;
    title: string;
    detail: string;
    status: number;
    errors: { [index: string]: string };
}

export interface UserDTO {
    userID: string;
    username: string;
    email: string;
    role: Role;
    lastLogin: Date;
}

export interface UserResponseDTO {
    user: UserDTO;
    organizations: OrganizationDTO[];
}

export interface OrganizationMemberDTO {
    id: number;
    email: string;
    type: OrganizationJoinSource;
    permission: OrganizationPermission;
}

export interface OrganizationDTO {
    organizationID: number;
    name: string;
    description: string;
}

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class RestApplicationClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/users
     * Java method: com.gameforgelabs.appstore.feature.users.controller.UserController.createUser
     */
    createUser(user: UserDTO, options?: O): RestResponse<UserResponseDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users`, data: user, options: options });
    }

    /**
     * HTTP POST /organizations
     * Java method: com.gameforgelabs.appstore.feature.organization.controller.OrganizationController.createOrganization
     */
    createOrganization(organization: OrganizationDTO, options?: O): RestResponse<OrganizationDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`organizations`, data: organization, options: options });
    }

    /**
     * HTTP GET /organizations
     * Java method: com.gameforgelabs.appstore.feature.organization.controller.OrganizationController.getOrganizations
     */
    getOrganizations(options?: O): RestResponse<OrganizationDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`organizations`, options: options });
    }

    /**
     * HTTP PUT /organizations/{organizationId}
     * Java method: com.gameforgelabs.appstore.feature.organization.controller.OrganizationController.addOrganizationMember
     */
    addOrganizationMember(organizationId: number, dto: OrganizationMemberDTO, options?: O): RestResponse<OrganizationMemberDTO[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`organizations/${organizationId}`, data: dto, options: options });
    }

    /**
     * HTTP DELETE /organizations/{organizationId}/member/{memberId}
     * Java method: com.gameforgelabs.appstore.feature.organization.controller.OrganizationController.expelOrganizationMember
     */
    expelOrganizationMember(organizationId: number, memberId: number, options?: O): RestResponse<OrganizationMemberDTO[]> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`organizations/${organizationId}/member/${memberId}`, options: options });
    }

    /**
     * HTTP GET /organizations/{organizationId}/members
     * Java method: com.gameforgelabs.appstore.feature.organization.controller.OrganizationController.getOrganizationMembers
     */
    getOrganizationMembers(organizationId: number, options?: O): RestResponse<OrganizationMemberDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`organizations/${organizationId}/members`, options: options });
    }
}

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type ErrorType = "INTERNAL_SERVER_ERROR" | "BAD_REQUEST" | "UNAUTHORIZED" | "FORBIDDEN" | "NOT_FOUND" | "METHOD_NOT_ALLOWED" | "CONFLICT" | "GONE" | "PRECONDITION_FAILED" | "UNSUPPORTED_MEDIA_TYPE" | "TOO_MANY_REQUESTS" | "SERVICE_UNAVAILABLE" | "REVOKED_ID_TOKEN" | "INSUFFICIENT_PRIVILEGES" | "USER_NOT_FOUND" | "USER_DELETED" | "ORGANIZATION_NOT_FOUND";

export type Role = "USER" | "ADMIN";

export type OrganizationJoinSource = "EMAIL" | "GROUP";

export type OrganizationPermission = "OWNER" | "MEMBER";

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios from "axios";
import * as Axios from "axios";

declare module "axios" {
    export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
        data: R;
    }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {

    constructor(private axios: Axios.AxiosInstance) {
    }

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: Axios.AxiosRequestConfig; }): RestResponse<R> {
        function assign(target: any, source?: any) {
            if (source != undefined) {
                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }

        const config: Axios.AxiosRequestConfig = {};
        config.method = requestConfig.method as typeof config.method;  // `string` in axios 0.16.0, `Method` in axios 0.19.0
        config.url = requestConfig.url;
        config.params = requestConfig.queryParams;
        config.data = requestConfig.data;
        assign(config, requestConfig.options);
        const copyFn = requestConfig.copyFn;

        const axiosResponse = this.axios.request(config);
        return axiosResponse.then(axiosResponse => {
            if (copyFn && axiosResponse.data) {
                (axiosResponse as any).originalData = axiosResponse.data;
                axiosResponse.data = copyFn(axiosResponse.data);
            }
            return axiosResponse;
        });
    }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
