import { makeAutoObservable } from 'mobx';
import AppClientService from './AppClientService';
import { OrganizationForm } from '../pages/Organization/model';

class ApplicationStore {
  constructor(private readonly appClient: AppClientService) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getOrganizations() {
    return this.appClient.getOrganizations();
  }

  async createOrganization(org: OrganizationForm) {
    return this.appClient.createOrganization(org as any);
  }
}

export default ApplicationStore;
