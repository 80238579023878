import { TableRowData } from "./BuildList";

const BuildsMock: TableRowData[] = [
  {
    platform: "ios",
    appVersion: "1.2.30",
    workspace: "Maple Story QA",
    architecture: "x86",
    description:
      'Dive into "Mystic Quest," an enchanting mobile game set in a magical world.',
    target: "APPLE_STORE",
    commit: "7f22",
    author: "Jane Smith",
    time: "2022-02-15 12:30:00",
    platformVersion: "15.5",
    buildId: "3d44a8c7f224fecc92d27cc12f20e4ea",
    buildType: "DEBUG",
    branch: "feature/mystic-quest-gameplay-enhancements",
    tags: ["iOS", "QA", "Feature-A", "Sprint-12"],
    fileSize: "150MB",
    downloadCount: 35,
    testCoverage: "80%",
    testResults: {
      passed: 350,
      failed: 10,
      skipped: 5,
    },
    issues: [
      {
        id: 1234,
        title: "Crash on startup",
        status: "Resolved",
      },
      {
        id: 5678,
        title: "UI glitch in level 3",
        status: "Open",
      },
    ],
  },
  {
    platform: "android",
    appVersion: "1.5.12",
    workspace: "Pet Rescue Saga",
    description: "This is a nice build",
    architecture: "x64",
    target: "AMAZON",
    commit: "9c41",
    author: "John Doe",
    time: "2022-03-10 14:20:00",
    platformVersion: "12.1",
    buildId: "8e12f4b6a937c0ed91a78f32c71f9e12",
    buildType: "RELEASE",
    tags: ["Android", "QA", "Feature-B", "Sprint-14"],
    branch: "feature/mystic-quest-gameplay-enhancements",
    fileSize: "120MB",
    downloadCount: 50,
    testCoverage: "85%",
    testResults: {
      passed: 420,
      failed: 15,
      skipped: 10,
    },
    issues: [
      {
        id: 2345,
        title: "Level 25 progress not saved",
        status: "Open",
      },
      {
        id: 6789,
        title: "Sound not working",
        status: "Resolved",
      },
    ],
  },
  {
    platform: "html5",
    appVersion: "3.2.18",
    workspace: "Farm Heroes Saga",
    architecture: "arm86",
    description: "This is a nice build",
    target: "GOOGLE_PLAY",
    commit: "b2e8",
    author: "Alice Brown",
    time: "2022-04-05 16:45:00",
    platformVersion: "N/A",
    buildId: "c987d5a2f123ac9e87d12f34e5f987cd",
    buildType: "DEBUG",
    tags: ["html5", "QA", "Feature-C", "Sprint-16"],
    branch: "feature/mystic-quest-gameplay-enhancements",
    fileSize: "200MB",
    downloadCount: 25,
    testCoverage: "90%",
    testResults: {
      passed: 500,
      failed: 8,
      skipped: 7,
    },
    issues: [
      {
        id: 3456,
        title: "In-app purchase not working",
        status: "In Progress",
      },
      {
        id: 7890,
        title: "Animation stuttering",
        status: "Resolved",
      },
    ],
  },
  {
    platform: "ios",
    appVersion: "2.0.0",
    workspace: "Clash of Clans",
    architecture: "arm64",
    description:
      "Introducing a new Clan War League system and balance updates.",
    target: "APPLE_STORE",
    commit: "1a8f",
    author: "Michael Johnson",
    time: "2022-05-10 10:20:00",
    platformVersion: "15.6",
    buildId: "1a2b3c4d5e6f7g8h9i0j1k2l3m4n5o6p",
    buildType: "RELEASE",
    branch: "feature/clan-war-league",
    tags: ["iOS", "QA", "Feature-D", "Sprint-18"],
    fileSize: "180MB",
    downloadCount: 40,
    testCoverage: "88%",
    testResults: {
      passed: 440,
      failed: 20,
      skipped: 15,
    },
    issues: [
      {
        id: 2468,
        title: "In-app purchase not working",
        status: "In Progress",
      },
      {
        id: 1357,
        title: "UI glitch in league screen",
        status: "Resolved",
      },
    ],
  },
  {
    platform: "android",
    appVersion: "2.6.15",
    workspace: "Call of Duty Mobile",
    architecture: "x64",
    description: "Bug fixes and performance improvements.",
    target: "GOOGLE_PLAY",
    commit: "1b9c",
    author: "Steve Rogers",
    time: "2022-05-15 11:30:00",
    platformVersion: "12.2",
    buildId: "1z2y3x4w5v6u7t8s9r0q1p2o3n4m5l6k",
    buildType: "DEBUG",
    branch: "bugfix/performance-improvements",
    tags: ["Android", "QA", "Bugfix", "Sprint-19"],
    fileSize: "220MB",
    downloadCount: 60,
    testCoverage: "75%",
    testResults: {
      passed: 375,
      failed: 25,
      skipped: 10,
    },
    issues: [
      {
        id: 1590,
        title: "Crash on startup",
        status: "Resolved",
      },
      {
        id: 7531,
        title: "Sound not working",
        status: "Open",
      },
    ],
  },
  {
    platform: "ios",
    appVersion: "3.5.22",
    workspace: "Fortnite Mobile",
    architecture: "x86",
    description: "New map updates and performance improvements.",
    target: "APPLE_STORE",
    commit: "2a7d",
    author: "Jessica Jones",
    time: "2022-06-01 09:15:00",
    platformVersion: "15.7",
    buildId: "2a3b4c5d6e7f8g9h0i1j2k3l4m5n6o7p",
    buildType: "RELEASE",
    branch: "feature/new-map-updates",
    tags: ["iOS", "QA", "Feature-E", "Sprint-21"],
    fileSize: "190MB",
    downloadCount: 45,
    testCoverage: "92%",
    testResults: {
      passed: 460,
      failed: 12,
      skipped: 13,
    },
    issues: [
      {
        id: 9632,
        title: "In-game chat not working",
        status: "Open",
      },
      {
        id: 7410,
        title: "UI glitch in inventory screen",
        status: "Resolved",
      },
    ],
  },
  {
    platform: "android",
    appVersion: "4.0.0",
    workspace: "PUBG Mobile",
    architecture: "x64",
    description: "Introducing the new Livik map and balance changes.",
    target: "AMAZON",
    commit: "3b6c",
    author: "Clark Kent",
    time: "2022-06-15 15:00:00",
    platformVersion: "12.3",
    buildId: "3a4b5c6d7e8f9g0h1i2j3k4l5m6n7o8p",
    buildType: "DEBUG",
    branch: "feature/livik-map",
    tags: ["Android", "QA", "Feature-F", "Sprint-22"],
    fileSize: "250MB",
    downloadCount: 70,
    testCoverage: "95%",
    testResults: {
      passed: 475,
      failed: 15,
      skipped: 10,
    },
    issues: [
      {
        id: 8520,
        title: "Crash on startup",
        status: "In Progress",
      },
      {
        id: 9642,
        title: "Sound not working",
        status: "Resolved",
      },
    ],
  },
  {
    platform: "html5",
    appVersion: "5.1.4",
    workspace: "Candy Crush Saga",
    architecture: "arm64",
    description: "New levels and bug fixes.",
    target: "FACEBOOK",
    commit: "4a5b",
    author: "Peter Parker",
    time: "2022-07-05 13:30:00",
    platformVersion: "N/A",
    buildId: "4a5b6c7d8e9f0g1h2i3j4k5l6m7n8o9p",
    buildType: "RELEASE",
    branch: "feature/new-levels",
    tags: ["html5", "QA", "Feature-G", "Sprint-24"],
    fileSize: "210MB",
    downloadCount: 30,
    testCoverage: "89%",
    testResults: {
      passed: 445,
      failed: 10,
      skipped: 5,
    },
    issues: [
      {
        id: 7532,
        title: "In-app purchase not working",
        status: "Resolved",
      },
      {
        id: 1598,
        title: "Animation stuttering",
        status: "Open",
      },
    ],
  },
  {
    platform: "ios",
    appVersion: "6.3.10",
    workspace: "Pokemon GO",
    architecture: "x86",
    description: "Introducing new generation Pokemon and bug fixes.",
    target: "APPLE_STORE",
    commit: "5b4a",
    author: "Bruce Wayne",
    time: "2022-07-20 17:00:00",
    platformVersion: "15.8",
    buildId: "5a6b7c8d9e0f1g2h3i4j5k6l7m8n9o0p",
    buildType: "DEBUG",
    branch: "feature/new-generation-pokemon",
    tags: ["iOS", "QA", "Feature-H", "Sprint-26"],
    fileSize: "170MB",
    downloadCount: 55,
    testCoverage: "87%",
    testResults: {
      passed: 435,
      failed: 20,
      skipped: 15,
    },
    issues: [
      {
        id: 8471,
        title: "Crash on startup",
        status: "Open",
      },
      {
        id: 2031,
        title: "GPS not working properly",
        status: "Resolved",
      },
    ],
  },
  {
    platform: "android",
    appVersion: "7.4.9",
    workspace: "Minecraft Pocket Edition",
    architecture: "x64",
    description: "Introducing new biomes and performance improvements.",
    target: "GOOGLE_PLAY",
    commit: "6c3a",
    author: "Tony Stark",
    time: "2022-08-10 18:45:00",
    platformVersion: "12.4",
    buildId: "6a7b8c9d0e1f2g3h4i5j6k7l8m9n0o1p",
    buildType: "RELEASE",
    branch: "feature/new-biomes",
    tags: ["Android", "QA", "Feature-I", "Sprint-28"],
    fileSize: "230MB",
    downloadCount: 65,
    testCoverage: "93%",
    testResults: {
      passed: 465,
      failed: 15,
      skipped: 10,
    },
    issues: [
      {
        id: 9482,
        title: "In-game chat not working",
        status: "In Progress",
      },
      {
        id: 2740,
        title: "UI glitch in crafting menu",
        status: "Resolved",
      },
    ],
  },
];

export default BuildsMock;
