import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";

const COOKIE_SESSION_STORAGE_KEY = "COOKIE_SESSION_STORAGE_KEY";

export const CookiePolicyModal: React.FC = () => {
  const acceptedCookie =
    localStorage.getItem(COOKIE_SESSION_STORAGE_KEY) != null;

  const [isModalOpen, setIsModalOpen] = useState(!acceptedCookie);

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.setItem(COOKIE_SESSION_STORAGE_KEY, "true");
  };

  if (acceptedCookie) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cookie Policy</ModalHeader>
        <ModalBody>
          <Text>
            We use cookies to improve your browsing experience on our website,
            to analyze our website traffic, and to understand where our visitors
            are coming from. By browsing our website, you consent to our use of
            cookies and other tracking technologies.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={closeModal}>
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
